<template>
  <CRow>
    <CCol col="12">
      <transition name="slide">
        <CCard>
          <CCardHeader>
            <strong>Estudiantes - Compromisos</strong>
            <div class=" card-header-actions">
              <form class="form-inline">
                <input id="user" type="hidden" value="<?php echo $user->id ?>"/>
                <b>Filas por p&aacute;gina</b>
                <select id="filasPorPagina" class="form-control mr-2">
                  <option>Todos</option>
                </select>

                <button class="btn btn-outline-dark mr-2" type="button"
                        @click="borrarFiltroBtnOnClick()">
                  <font-awesome-icon icon="fa-sort-amount-up-alt"/>
                  Quitar Filtro
                </button>

                <button class="btn btn-outline-primary ml-2" data-html="true"
                        data-toggle="tooltip" title="Inscribir un <b>nuevo</b> estudiante" type="button"
                        @click="nuevoBtnOnClick()">
                  <font-awesome-icon icon="fa-plus"/>
                  Nuevo
                </button>

                <button class="btn btn-outline-success ml-2" data-html="true"
                        data-toggle="tooltip"
                        title="Para exportar todos los datos en un archivo Excel<br/>seleccione la opci&oacute;n <u>Todos</u> en <b>Filas por p&aacute;gina</b>"
                        type="button"
                        @click="excelBtnOnClick()">
                  <font-awesome-icon icon="fa-file-excel"/>
                  Exportar a Excel
                </button>
              </form>
            </div>
          </CCardHeader>
          <CCardBody class="p-0">
            <CAlert
                :show.sync="dismissCountDown"
                color="primary"
                fade
            >
              ({{ dismissCountDown }}) {{ message }}
            </CAlert>
            <JqxGrid
                ref="gridSystem" :altrows="true"
                :columns="columns" :filterable="true"
                :height="'500px'" :showfilterrow="true"
                :sortable="true" :source="dataAdapter"
                :theme="'bootstrap'" :width="'100%'"
                :localization="localization"
                :columnsresize="true"
            />
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";

var sourceLst;
var address;
var modalPdf;

export default {
  name: 'Alumnos',
  components: {
    JqxGrid,
    jqxInput
  },
  data: () => {
    return {
      items: [],
      fields: ['codigo_alumno', 'apellido1', 'apellido2', 'nombres', 'carrera', 'gestion', 'estado', 'mostrar', 'documentos', 'editar', 'eliminar'],
      currentPage: 1,
      perPage: 7,
      totalRows: 0,
      you: null,
      message: '',
      showMessage: false,
      dismissSecs: 7,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      sedeSeleccionada: localStorage.sedeSeleccionada,
      dataAdapter: new jqx.dataAdapter(sourceLst, {
        loadError: function (xhr, status, error) {
          console.log("Error ocurrido al obtener los datos del servidor, " + status + ", " + error);
          location.href = location.protocol + "//" + location.host + "/#/login";
        }
      }),
      columns: [
        {
          text: 'Código Alumno', datafield: "codigo_alumno", width: '9%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Código<br />Alumno</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Código Alumno"});
          }
        },
        {
          text: 'Apellido1', datafield: "apellido1", width: '11%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Primer<br />apellido</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido1"});
          }
        },
        {
          text: 'Apellido2', datafield: "apellido2", width: '10%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Segundo<br />apellido</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido2"});
          }
        },
        {
          text: 'Nombres', datafield: "nombres", width: '11%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Nombres"});
          }
        },
        {
          text: 'Carrera', datafield: "carrera", width: '21%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Carrera"});
          }
        },
        {
          text: 'Gestion', datafield: "gestion", width: '6%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Gestion"});
          }
        },
        {
          text: 'Estado', datafield: "estado", width: '5%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Estado"});
          }
        },
        {
          text: "Documentos", width: '7%', columntype: "button",
          cellclassname: "btn-red",
          hidden: modalPdf.hasRole('documentos'),
          renderer: function () {
            return '';
          },
          cellsrenderer: function () {
            return "Hoja de ruta"
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");

            let rowData = grid.jqxGrid('getrowdata', row);

            axios.get(
                address + '/api/inscripcion/' + rowData.codigo_alumno + '/documentospdf?token=' + localStorage.getItem("api_token"),
                {responseType: 'blob'}
            ).then((response) => {
              window.open(URL.createObjectURL(response.data));
            })
          }
        },
        {
          text: '', width: '7%', cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return '<a class="btn btn-warning btn-block" href="/#/inscripcion/' + rowdata.codigo_alumno + '/compromisos" title="Recibir Documentos">Compromisos</a>';
          }, filterable: false, sortable: false
        },
      ],
      localization: {
        'emptydatastring': "No existen datos para esta SEDE",
      },
    }
  },
  beforeCreate: function () {
    const url = this.$apiAdress + '/api/inscripcion?token=' + localStorage.getItem("api_token") + '&sede_id=' + localStorage.sedeSeleccionada;
    address = this.$apiAdress;
    sourceLst = {
      url: url,
      datafields: [
        {name: 'codigo_alumno', type: "string"},
        {name: 'apellido1', type: "string"},
        {name: 'apellido2', type: "string"},
        {name: 'nombres', type: "string"},
        {name: 'carrera', type: "string"},
        {name: 'gestion', type: "string"},
        {name: 'estado', type: "string"},
        {name: 'documentos', type: "string"},
        {name: 'editar', type: "string"},
        {name: 'eliminar', type: "string"},
      ],
      data: {
        sede: this.sede
      },
      datatype: "json",
      root: "Rows",
      id: 'codigo_alumno',
      sortcolumn: 'apellido1',
      sortdirection: 'asc',
      cache: false,
      filter: () => {
        this.$refs.gridSystem.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.gridSystem.updatebounddata("sort");
      },
    };
  },
  paginationProps: {
    align: 'center',
    doubleArrows: false,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next'
  },
  methods: {
    hasRole(roleUser) {
      let roles = localStorage.getItem("roles");
      if (roles != null) {
        roles = roles.split(',')
        if (roles != null && roles.indexOf(roleUser) >= 0) {
          return true
        } else {
          return false;
        }
      }
    },
    getBadge(status) {
      return status === 'Active' ? 'success'
          : status === 'Inactive' ? 'secondary'
              : status === 'Pending' ? 'warning'
                  : status === 'Banned' ? 'danger' : 'primary'
    },
    userLink(id) {
      return `alumnos/${id.toString()}`
    },
    editarLink(id) {
      return `alumnos/${id.toString()}/edit`
    },
    documentosLink(id) {
      return `${id.toString()}/documentos`
    },
    mostrarUser(id) {
      const userLink = this.userLink(id);
      this.$router.push({path: userLink});
    },
    documentosUser(id) {
      const userLink = this.documentosLink(id);
      this.$router.push({path: userLink});
    },
    editarUser(id) {
      const editarLink = this.editarLink(id);
      this.$router.push({path: editarLink});
    },
    eliminarUser(id) {
      let self = this;
      let userId = id;
      axios.post(this.$apiAdress + '/api/alumnos/' + id + '?token=' + localStorage.getItem("api_token"), {
        _method: 'DELETE'
      })
          .then(function (response) {
            self.message = 'Successfully eliminard user.';
            self.showAlert();
            self.getAlumnos();
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    excelBtnOnClick: function () {
      this.$refs.gridSystem.hidecolumn('documentos');
      this.$refs.gridSystem.hidecolumn('editar');
      this.$refs.gridSystem.hidecolumn('eliminar');
      let date = new Date();
      let dateStr =
          date.getFullYear() + "" +
          ("00" + (date.getMonth() + 1)).slice(-2) + "" +
          ("00" + date.getDate()).slice(-2) + "_" +
          ("00" + date.getHours()).slice(-2) + "" +
          ("00" + date.getMinutes()).slice(-2) + "" +
          ("00" + date.getSeconds()).slice(-2);
      this.$refs.gridSystem.exportdata('xls', 'Inscritos' + dateStr, true, null, true, modalPdf.$apiAdress + "/api/reporte/exportarxls/" + '?token=' + localStorage.getItem("api_token"));
      this.$refs.gridSystem.showcolumn('documentos');
      this.$refs.gridSystem.showcolumn('editar');
      this.$refs.gridSystem.showcolumn('eliminar');
    },
    nuevoBtnOnClick: function () {
      this.$router.push({path: '/inscripcion/nuevo'});
    },
    borrarFiltroBtnOnClick: function () {
      this.$refs.gridSystem.clearfilters();
    },
  },
  computed: {},
  mounted() {
    modalPdf = this;
    this.$root.$on('sedeSeleccionada', data => {
      left.sede = data;
    });
  },
}
</script>